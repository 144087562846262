
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { setSignupData, finishSignup } from '@/application/services/auth.js'
import { getCountriesList } from '@/application/utils/countries'
import { extractParamsFromUrl } from '@/application/utils/tokens'
import { getCookie, setCookie } from '@/application/utils/cookie'
import { store } from '@/application/store'
import { router } from '@/application/router'
import { getLocalStorageValue, setLocalStorageValue } from '@/application/utils/localstorage'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useModal } from '@/application/composables/modal'
import { hideVerificationModal } from '@/application/composables/phoneVerification'
import { trackRegistrationComplete } from '@/application/utils/tracking-scripts'

export function useSignUpForm() {
  const i18n = useI18n()
  const { af_id } = extractParamsFromUrl()
  if (af_id) {
    setLocalStorageValue('af_id', af_id, 86400 * 30)
  }
  const affiliate_id = af_id || getLocalStorageValue('af_id')
  const isDisabled = ref(false)
  const affiliate = ref(null)
  const { openModal } = useModal()
  const formSchema = computed(() => ({
    email: {
      label: i18n.t('forms.checkout.email'),
      name: 'email',
      as: 'input',
      type: 'text',
      rules: 'required|email|disposable_email',
      cols: ' col-12', // col-sm-6',
      inputClass: 'form-control',
    },
    // password: {
    //   label: i18n.t('forms.checkout.password'),
    //   name: 'password',
    //   as: 'input',
    //   type: 'password|min:8|alpha_num',
    //   rules: 'required',
    //   cols: ' col-12 col-sm-6',
    //   inputClass: 'form-control',
    // },
    name: {
      label: i18n.t('forms.checkout.name'),
      name: 'name',
      as: 'input',
      type: 'text',
      rules: 'required',
      cols: ' col-12 col-sm-6',
      inputClass: 'form-control',
    },
    lastName: {
      label: i18n.t('forms.checkout.last_name'),
      name: 'lastName',
      as: 'input',
      type: 'text',
      rules: 'required',
      cols: ' col-12  col-sm-6',
      inputClass: 'form-control',
    },
    phoneNumber: {
      label: i18n.t('phoneNumber'),
      name: 'phoneNumber',
      type: 'text',
      as: 'input',
      rules: 'required|phone_mobile',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.checkout.phone_description'),
    },
    country: {
      label: i18n.t('forms.checkout.country'),
      name: 'country',
      type: 'text',
      as: 'select',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      children: getCountriesList().map(country => ({
        tag: 'option',
        value: country.code,
        text: country.name,
      })),
    },
    postalCode: {
      label: i18n.t('forms.checkout.postalCode'),
      name: 'postalCode',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.checkout.postalCode_description'),
    },
    companyName: {
      label: i18n.t('forms.checkout.companyName'),
      name: 'companyName',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
    companyWebSite: {
      label: i18n.t('forms.checkout.companyWebSite'),
      name: 'companyWebSite',
      type: 'text',
      as: 'input',
      rules: 'url:false',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
    agreement: {
      htmlLabel: i18n.t('termsRulesAgreement'),
      name: 'agreement',
      type: 'checkbox',
      as: 'input',
      rules: 'required',
      validateOnMount: false,
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
    },
  }))

  const storedDataToFormData = (storedData) => {
    if (storedData.affiliate_id) {
      affiliate.value = storedData.affiliate_id
    } else if (affiliate_id) {
      affiliate.value = affiliate_id
    }

    return {
      email: storedData.email,
      // password: storedData.password,
      name: storedData.first_name,
      lastName: storedData.last_name,
      phoneNumber: storedData.phone_number,
      companyName: storedData.company_name,
      companyWebSite: storedData.company_website,
      country: storedData.country,
      postalCode: storedData.postal_code,
    }
  }

  const formDataToStoreData = (formData) => {
    const res = {
      email: formData.email,
      // password: formData.password,
      first_name: formData.name,
      last_name: formData.lastName,
      phone_number: formData.phoneNumber,
      company_name: formData.companyName,
      company_website: formData.companyWebSite,
      country: formData.country,
      postal_code: formData.postalCode,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    if (affiliate.value) {
      res.affiliate_id = affiliate.value
    }
    return res
  }

  const getFormSchema = (formSchema, countries) => {
    formSchema.country.children = countries.map(country => ({
      tag: 'option',
      text: country.name,
      value: country.code,
    }))
    return formSchema
  }

  const submitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('continue'),
  }))
  const submit = (data) => {
    isDisabled.value = true
    const dataToStore = formDataToStoreData(data)
    finishSignup(dataToStore)
      .then(({ data }) => {
        if (data.success) {
          setCookie({ session_token: data.session_token, expire_date: data.expire_date })
          isDisabled.value = false
          hideVerificationModal(3600)
          trackRegistrationComplete(dataToStore)
          store.commit('changeAuthStatus', getCookie('session_token'))
          router.push({ name: 'dashboard' })
        }
        isDisabled.value = false
      })
      .catch(err => {
        openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response))
        isDisabled.value = false
      })
  }

  const changeHandler = (name, values, errors) => {
    setSignupData(formDataToStoreData(values))
  }

  return {
    formSchema,
    submitButton,
    submit,
    isDisabled,
    storedDataToFormData,
    formDataToStoreData,
    getFormSchema,
    changeHandler,
  }
}
