<template>
  <h3 class="mb-4 text-center">{{ $t('Signup') }}</h3>
  <div class="form-group mb-3">
      <DynamicForm
        :schema="formSchema"
        :initial-field-value="formData"
        :button-data="submitButton"
        :is-disabled="isDisabled"
        form-class="justify-content-center"
        @submit="submit"
        @changeHandler="changeHandler"
      >
      </DynamicForm>
    <p class="mb-4 text-muted text-center">{{$t('signinProposal')}}
      <router-link :to="{ name: 'sign-in' }" class="license-preview">{{$t('signin')}}</router-link>
    </p>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue'
import { useSignUpForm } from '@/application/composables/signupForm.js'
import { onMounted, ref } from 'vue'
import { getSignupData } from '@/application/services/auth'

export default {
  name: 'SignUpForm',
  components: {
    DynamicForm,
  },
  setup() {
    const isLoading = ref(true)
    const formData = ref(null)

    onMounted(async() => {
      const data = await getSignupData()
      formSchema.value = getFormSchema(formSchema.value, data.countries)
      formData.value = storedDataToFormData(data)
      isLoading.value = false
    })

    const {
      formSchema,
      submitButton,
      submit,
      storedDataToFormData,
      getFormSchema,
      changeHandler,
      isDisabled,
    } = useSignUpForm()

    return {
      formSchema,
      submit,
      submitButton,
      formData,
      changeHandler,
      isDisabled,
    }
  },
}
</script>
